import React from 'react';
import '../../styles/pages/about.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Components
import Layout from '../layout';
import Header from '../header';

const about = {
  en: {
    title: 'JNA Aircon introduction',
  },
  zh: {
    title: '關於捷納',
  },
};

const About = (props) => {
  const {
    pageContext: { langKey },
    // data: {
    //   markdownRemark: { html },
    // },
  } = props;

  return (
    <Layout>
      <Container className="About">
        <Header title={about[langKey].title} />
        <Row>
          <Col md={10} lg={5}>
            <article className="About--article">
              {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
              aliquyam erat, sed diam voluptua.
            </article>
          </Col>
          <Col md={10} lg={{ span: 5, offset: 1 }}>
            <article className="About--article">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
              aliquyam erat, sed diam voluptua.
            </article>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default About;
